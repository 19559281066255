import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context";
import Rotate from "./Rotate";
import Button from "./Button";
import leftArrow from "../img/left.png";
import rightArrow from "../img/right.png";
import white_tick from "../img/white_tick.png";
import crossBtn from "../img/cross.png";
import {
  totalImagesUploadStatus,
  userLogs,
  patLogs,
  feedBackStatus
} from "../context/utils";
import car_inspection from "../img/car_inspection.png";

// import ReactGA from "react-ga4";

let allMandatoryCompleted = true;
let allCompleted = true;
let alignment = false;
let mandatoryMissing = [];
let totalMissing = [];
let logsbugs = true;
let damageModuleExists = false;
let interval;
let totalModuleComplete = "";
let interval2;

const Menu = () => {
  const {
    damageModuleCompleted,
    stepCovered,
    setStepCovered,
    showAlert,
    config,
    setCurrentBlock,
    screen,
    setScreen,
    condition,
    isLandscape,
    setLandscape,
    mandatory,
    completed,
    setCompleted,
    mandatoryCompleted,
    setMandatoryCompleted,
    count1,
    setCount1,
    setUploaded,
    scrollX,
    setscrollX,
    scrolEnd,
    setscrolEnd,
    setStopPing,
    setCurrentPage,
    inspectionId,
    showFeedBackWarning,
    setShowFeedBackWarning,
    imageFeedbackPopupRef,
    setImageFeedbackPopupRef
  } = useContext(AppContext);

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const modalRef = useRef();
  let scrl = useRef();
  const menufeedBackPopUp = useRef();
  const imageFeebackPopup = useRef();
  const [reduce, setReduce] = useState(false);
  const [menuBlocks, setMenuBlocks] = useState(config["blocks"]);
  const [tempFlag, setTempFlag] = useState(true);
  const [intervalRef, setIntervalRef] = useState(true);



  window.onresize = () => {
    //patLogs(land() ? "Landscape Mode" : "Portrait Mode", inspectionId);
    //userLogs({ position: 11, screen_orientation: "yes", inspectionId });
    setLandscape(land() ? "Landscape Mode" : "Portrait Mode");
  };

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const success = () => {
    if (!allMandatoryCompleted || !allCompleted) {

      patLogs(
        `Submit button clicked and warning given to user that some module is not completed`,
        inspectionId
      );
      // patLogs(
      //   `Submit button clicked and warning given to user that some module is not completed -> ${totalMissing.join(
      //     ", "
      //   )}`,
      //   inspectionId
      // );
      if (imageFeedbackPopupRef) {
        imageFeebackPopup.current.style.display = "none";
        setImageFeedbackPopupRef(false);
      }
      modalRef.current.style.display = "flex";
      return;
    }
    // console.log("success", feedBackStatus());

    if (feedBackStatus() === "checking") {
      menufeedBackPopUp.current.style.display = "flex";
    } else if (feedBackStatus() === "Feedback") {
      menufeedBackPopUp.current.style.display = "none";
      if (process.env.REACT_APP_IMAGE_SLIDER) {
        sortBlock("Feedback");
      }
      setShowFeedBackWarning(true);
      clearInterval(interval);
    } else if (feedBackStatus() === "noFeedback") {
      clearInterval(interval);
      setScreen("success");
    } else {
      clearInterval(interval);
      setScreen("success");
    }
    checkData();
  };

  const checkData = () => {
    interval = setInterval(() => {
      if (feedBackStatus() === "checking") {
        menufeedBackPopUp.current.style.display = "flex";
      } else if (feedBackStatus() === "Feedback") {
        clearInterval(interval);
        menufeedBackPopUp.current.style.display = "none";
        setShowFeedBackWarning(true);
      } else if (feedBackStatus() === "noFeedback") {
        clearInterval(interval);
        menufeedBackPopUp.current.style.display = "none";
        setScreen("success");
      } else {
        clearInterval(interval);
        setScreen("success");
      }
    }, 1000);
  };

  const modalSuccess = () => {

    patLogs(
      `Warning given to user, some module is not completed, still clicked to submit button`,
      inspectionId
    );
    // patLogs(
    //   `Warning given to user, this module ${totalMissing.join(
    //     ", "
    //   )}  is not completed, still clicked to submit button`,
    //   inspectionId
    // );
    // console.log("modalSuccess", feedBackStatus());

    if (feedBackStatus() === "checking") {
      menufeedBackPopUp.current.style.display = "flex";
    } else if (feedBackStatus() === "Feedback") {
      menufeedBackPopUp.current.style.display = "none";
      setShowFeedBackWarning(true);
      if (process.env.REACT_APP_IMAGE_SLIDER) {
        sortBlock("Feedback");
      }
      clearInterval(interval);
    } else if (feedBackStatus() === "noFeedback") {
      clearInterval(interval);
      setScreen("success");
    } else {
      clearInterval(interval);
      setScreen("success");
    }
    checkData();
  };

  const sortBlock = (feedback) => {
    let tempCurrentBlock = menuBlocks;
    if (feedback === "Feedback") {
      tempCurrentBlock.sort((a, b) => {
        if (a["tag"] === '360 Image') return -1;
        if (b["tag"] === '360 Image') return 1;
        return 0;
      });
    }
    else {
      const totalMissingSet = new Set(totalMissing);
      const mandatoryMissingSet = new Set(mandatoryMissing);

      tempCurrentBlock.sort((a, b) => {
        const aMandatoryIncomplete = mandatoryMissingSet.has(a["id"]);
        const bMandatoryIncomplete = mandatoryMissingSet.has(b["id"]);

        const aIncomplete = totalMissingSet.has(a["id"]);
        const bIncomplete = totalMissingSet.has(b["id"]);

        // Sort by mandatory and incomplete status
        if (aMandatoryIncomplete && !bMandatoryIncomplete) return -1;
        if (!aMandatoryIncomplete && bMandatoryIncomplete) return 1;

        if (aMandatoryIncomplete && bMandatoryIncomplete) return 0;

        if (aIncomplete && !bIncomplete) return -1;
        if (!aIncomplete && bIncomplete) return 1;

        return 0;
      });
    }
    setMenuBlocks(tempCurrentBlock);
  }

  const removeModal = () => {
    // patLogs(
    //   `user clicked cross button to close warning and move to menu page`,
    //   inspectionId
    // );
    modalRef.current.style.display = "none";
  };


  useEffect(() => {
    config["blocks"].map((e) => {
      if (Object.values(e).includes("damage")) {
        damageModuleExists = true;
      }
    });
    if (logsbugs) {
      // ReactGA.send({
      //   hitType: "pageview",
      //   page: "Menu Page",
      //   title: "Menu Page",
      // });
      //patLogs(land() ? "Landscape Mode" : "Portrait Mode", inspectionId);
      // userLogs({ position: 8, last_page: "menu page", inspectionId });
      logsbugs = false;
    }
    setStopPing(true);
    allCompleted = true;
    allMandatoryCompleted = true;
    mandatoryMissing = [];
    totalMissing = [];
    setLandscape(land());
    if (config["blocks"].length <= 3) {
      alignment = true;
    }
    Object.keys(mandatory).map((item) => {
      if (mandatory[item] === "pending") {
        mandatoryMissing.push(item);
        allMandatoryCompleted = false;
      }
    });
    Object.keys(condition).map((item) => {
      if (condition[item] === "pending") {
        totalMissing.push(item);
        allCompleted = false;
      }
    });
    setCount1(count1 + 1);
    if (allCompleted) setCompleted(true);
    if (allMandatoryCompleted) setMandatoryCompleted(true);

    config["blocks"].map((e) => {
      if (e["id"].length > 14) {
        setReduce(true);
      }
    });
    totalModuleComplete = "";
    Object.keys(condition).map((e) => {
      if (condition[e] === "completed") {
        totalModuleComplete = e + "," + totalModuleComplete;
      }
    });
    userLogs({
      position: 13,
      completed_module: totalModuleComplete,
      inspectionId,
    });
    if (Object.keys(config).includes("TimeOut")) {
      setTimeout(() => {
        // console.log(config["TimeOut"]["timeInMinutes"] * 1000 * 60);
        if (!["success"].includes(screen)) {
          setScreen("timeOut");
        }
      }, config["TimeOut"]["timeInMinutes"] * 1000 * 60);
    }
    if (process.env.REACT_APP_IMAGE_SLIDER && totalModuleComplete.length > 0) {
      sortBlock();
    }

    if (imageFeedbackPopupRef) {
      imageFeebackPopup.current.style.display = "flex";
    }

    if (isLandscape) {
      if (Object.keys(config).includes("directModule") && config["directModule"]) {
        clearInterval(interval);
        patLogs(`Switch to video module directly`, inspectionId);
        setCurrentBlock(config["blocks"][0]);
        setScreen("block");
      }
    }
  }, [isLandscape]);

  useEffect(() => { }, [showFeedBackWarning]);

  useEffect(() => { userLogs({ position: 8, last_page: "menu page", inspectionId }) },[]);

  return !isLandscape ? (
    <Rotate />
  ) : (
    <div className="process" style={{ position: "relative" }}>
      {
        Object.keys(config).includes("directModule") && config["directModule"] ? "" : <h2>{config["menu-page"]["title"]}</h2>
      }
      <div ref={imageFeebackPopup} className="imageFeedBackCompletePopup">
        <div className="imageFeedBackInnerDiv" style={{ zIndex: 5 }}>
          <p>
            {Object.keys(config).includes("imageFeedback") &&
              Object.keys(config["imageFeedback"]).includes("ImageAnalysisCompleteText")
              ? config["imageFeedback"]["ImageAnalysisCompleteText"]
              : "Analysis is completed please click on submit and upload button"
            }
          </p>
          <div className="modal-btn-container">
            <Button
              text={config["menu-page"]["btn-text"]}
              disabled={!mandatoryCompleted}
              onclick={success}
            />
          </div>
        </div>
      </div>

      <div ref={menufeedBackPopUp} className="imageFeedBackPopup">
        <div style={{ zIndex: 5 }}>
          <p>
            {Object.keys(config).includes("imageFeedback") &&
              Object.keys(config["imageFeedback"]).includes("ImageAnalysisMssg2")
              ? config["imageFeedback"]["ImageAnalysisMssg2"]
              : "Please wait for some time while we analyse the images"}
          </p>
          <img
            style={{ width: "100px", height: "100px" }}
            src={car_inspection}
          />
        </div>
      </div>

      <div className="process-main">
        <div
          className="left-btn"
          disabled={scrollX === 0}
          onClick={() => slide(-200)}
          style={alignment ? { display: "none" } : {}}
        >
          <img
            src={leftArrow}
            style={scrollX === 0 ? { opacity: "0.3" } : {}}
          />
        </div>
        <div
          className="process-steps"
          ref={scrl}
          onScroll={scrollCheck}
          style={
            alignment
              ? { display: "flex", justifyContent: "center", width: "100vw" }
              : {
                display: "flex",
                justifyContent: "flex-start",
                width: "80vw",
                overflowX: "scroll",
                scrollBehavior: "smooth",
              }
          }
        >
          {menuBlocks.map((item, index) => {
            if (
              item["tag"] === "360 Image" &&
              feedBackStatus() === "Feedback" &&
              showFeedBackWarning === true
            ) {
              return (
                <div key={index}>
                  <div
                    className="menu-options"
                    style={reduce ? { fontSize: "13px" } : null}
                  >
                    {item["mandatory"] ? (
                      <h4 style={{ color: "red" }} key={index}>
                        {item["id"]}&#42;
                      </h4>
                    ) : (
                      <h4 style={{ color: "red" }} key={index}>
                        {item["id"]}
                      </h4>
                    )}
                    <div
                      className="process-vin"
                      style={{
                        border: "2px solid red",
                      }}
                      onClick={() => {
                        clearInterval(interval);
                        setCurrentBlock(item);
                        //patLogs(`click to ${item.tag}`, inspectionId);
                        setScreen("block");
                      }}
                    >
                      <img src={item["link"]} alt="123"></img>
                    </div>
                  </div>
                </div>
              );
            }
            if (condition[item["id"]] === "completed") {
              return (
                <div key={index}>
                  <div
                    className="menu-options"
                    style={reduce ? { fontSize: "13px" } : null}
                  >
                    {item["mandatory"] ? (
                      <h4
                        style={{ color: config["colors"]["complete-block"] }}
                        key={index}
                      >
                        {item["id"]}&#42;
                      </h4>
                    ) : (
                      <h4
                        style={{ color: config["colors"]["complete-block"] }}
                        key={index}
                      >
                        {item["id"]}
                      </h4>
                    )}
                    <div
                      className="process-vin"
                      style={{
                        border: `2px solid ${config["colors"]["complete-block"]}`,
                      }}
                    >
                      <div className="greenCircleTick">
                        <img src={white_tick} alt="1232"></img>
                      </div>
                      <img src={item["link"]} alt="123"></img>
                    </div>
                  </div>
                </div>
              );
            }
            return Object.keys(config).includes("directModule") && config["directModule"] ? "" : (
              <div key={index}>
                <div
                  className="menu-options"
                  style={reduce ? { fontSize: "14px" } : null}
                >
                  {item["mandatory"] ? (
                    <h4 key={index}>{item["id"]}&#42;</h4>
                  ) : (
                    <h4 key={index}>{item["id"]}</h4>
                  )}
                  <div
                    className="process-vin"
                    style={{
                      border: `1px solid ${config["colors"]["incomplete-block"]}`,
                    }}
                    onClick={() => {
                      setCurrentBlock(item);
                      //patLogs(`click to ${item.tag}`, inspectionId);
                      setScreen("block");
                    }}
                  >
                    <img src={item["link"]} alt="123"></img>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="right-btn"
          disabled={scrolEnd}
          onClick={() => slide(+200)}
          style={alignment ? { display: "none" } : {}}
        >
          <img src={rightArrow} style={scrolEnd ? { opacity: "0.3" } : {}} />
        </div>
      </div>
      <div className="InternetWarning">
        <p>
          {feedBackStatus() === "Feedback" && showFeedBackWarning === true
            ? Object.keys(config).includes("imageFeedback") &&
              Object.keys(config["imageFeedback"]).includes("recaptureMssg")
              ? config["imageFeedback"]["recaptureMssg"]
              : "Few images captured incorrectly. Please click red sections to recapture"
            : showAlert && showAlert}
        </p>
      </div>
      <div className="modal-btn-container">
        <Button
          text={config["menu-page"]["btn-text"]}
          disabled={!mandatoryCompleted}
          onclick={success}
        />
      </div>
      <div ref={modalRef} className="menu-modal-container">
        {!mandatoryCompleted ? (
          <div className="menu-modal">
            <div className="menu-back-btn">
              <i className="fas fa-times fa-lg" onClick={removeModal}></i>
            </div>
            <h4>{config["menu-page"]["mandatory-modal-text"]}</h4>
            <h4>{mandatoryMissing.join(", ")}</h4>
          </div>
        ) : mandatoryCompleted && !completed ? (
          <div className="menu-modal">
            {!damageModuleCompleted &&
              Object.keys(config["menu-page"]).includes(
                "damageConfirmationPopup"
              ) &&
              damageModuleExists ? (
              <>
                <h4>{config["menu-page"]["damageConfirmationPopup"]}</h4>
                <br></br>
                <br></br>
                <div className="damageConfirmationPopup">
                  <Button
                    text={config["menu-page"]["damageConfirmationPopupYesBtn"]}
                    onclick={() => {
                      modalSuccess();
                      modalRef.current.style.display = "none";
                    }}
                  />
                  <Button
                    text={config["menu-page"]["damageConfirmationPopupNoBtn"]}
                    onclick={() => {
                      modalRef.current.style.display = "none";
                      removeModal();
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="menu-back-btn">
                  <i className="fas fa-times fa-lg" onClick={removeModal}></i>
                </div>
                <h4>{config["menu-page"]["optional-modal-text"]}</h4>
                <h4>{totalMissing.join(", ")}</h4>
                <div className="modal-btn-container">
                  <Button
                    text={config["menu-page"]["modal-btn-next"]}
                    onclick={() => {
                      modalRef.current.style.display = "none";
                      modalSuccess();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Menu;
