import React, { useContext, useEffect, useRef, useState } from "react";
import takeCamera from "../img/takeCamera.png";
import getStartedGif from "../img/akgetStarted.gif";
import arrrow from "../img/zizo_arrow.gif";
import leftArrow from "../img/left.png";
import rightArrow from "../img/right.png";
import { AppContext } from "../context";
import gradiantSVG from "../img/zizoGradiant.gif";
import zizoHand from "../img/zizoOrangeHand.png";
import white_tick from "../img/white_tick.png";
import redRecord from "../img/redRecord.gif";
import greenRecord from "../img/greenRecord.gif";
import ZizoClose from "./zizoSVG/ZizoClose";
import ZizoFar from "./zizoSVG/ZizoFar";
import Rotate from "./Rotate";
import plusSvg from "../img/plus.svg";
import { addImageToList, userLogs, patLogs, resumeFlowFinalSubmitHandler, makeRequest } from "../context/utils";

let logbugs = true;
let cameraStarted = false;
let track = null;
let imgData = null;
let zoomInRes = [];
let zoomOutRes = [];
let interval;
let apiInterval;
let apiTag = "CAR_ZI";
let zoomInFlag = true;
let zoomOutFlag = true;
let recordGifFlag = false;
let apiCallTag = "Zoom_In";
let captured = 0;
let alignment = false;
let allowStopBtn = false;
let lambdaSendingFlag = true;
let takeMoreList = [true, false];

const NewZIZO = () => {

    const {
        clientId,
        setScreen,
        condition,
        setCondition,
        currentBlock,
        config,
        showAlert,
        isLandscape,
        setLandscape,
        page,
        setPage,
        scrollX,
        setscrollX,
        setscrolEnd,
        scrolEnd,
        mandatory,
        setMandatory,
        inspectionId,
    } = useContext(AppContext);

    const [apiFlag, setApiFlag] = useState(false);
    const [camBoxFlag, setCamBoxFlag] = useState(true);
    const [selectGradText, setSelectGradText] = useState(0);
    const [selectGuidanceText, setSelectGuidanceText] = useState(0);
    const [currentGif, setCurrentGif] = useState(0);
    const [alignmentFlag, setAlignmentFlag] = useState(true);
    const [count, setCount] = useState(1);
    const [recordBtnFlag, setRecordBtnFlag] = useState(true);
    const listCount = currentBlock["count"];

    const constraints = {
        video: Object.keys(config).includes("aspectRatio") ?
            {
                width: Object.keys(config).includes("resolution") ? config["resolution"]["width"] : 1920,
                height: Object.keys(config).includes("resolution") ? config["resolution"]["height"] : 1080,
                facingMode: "environment",
                aspectRatio: 4 / 3,
                zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 }
            } : {
                width: Object.keys(config).includes("resolution") ? config["resolution"]["width"] : 1920,
                height: Object.keys(config).includes("resolution") ? config["resolution"]["height"] : 1080,
                facingMode: "environment",
                zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 }
            },
        audio: false,
    };

    let webCamPromise = null;
    const videoRef = useRef();
    const startBtn = useRef();
    const canvasRef = useRef();
    const lambdaGuidRef = useRef();
    const pathGuidRefLeft = useRef();
    const pathGuidRefRight = useRef();
    const gradiantRef = useRef();
    const zizoBtnYes = useRef();
    const zizoBtnNo = useRef();
    const recordingRef = useRef();
    const stopHand = useRef();
    const recordGifRef = useRef();
    const recordGuidTxtRef = useRef();
    const gradiantTextRef = useRef();
    const red_box = useRef();
    const zizoArrow = useRef();
    const zizoGuidance = useRef();
    const zizoBtns = useRef();
    let scrl = useRef();
    const gradiantText = ["Is the Damage visible & fitting within the box", "Hold the position & click on record button", "Video captured successfully, Please stop the recording", "Please fit the damage inside the green box and confirm us once done"];
    const cameraGuidanceText = ["Before starting the recording, please focus on the damage by standing close to it", "Please point your camera towards the car", "Move closer to the damage area until the box turns green"];

    if (logbugs) {
        patLogs("New ZIZO Module -> At instruction page", inspectionId);
        userLogs({ position: 8, last_page: "zizo module", inspectionId });
    }

    logbugs = false;
    const land = () => window.innerWidth > window.innerHeight * 1.2;
    window.onresize = () => {
        setLandscape(land());
        if (cameraStarted) startCamera();
    };

    const End = () => {
        cameraStarted = false;
        if (currentBlock["mandatory"]) {
            let temp1 = mandatory;

            temp1[currentBlock["id"]] = "completed";
            setMandatory(temp1);
        }
        let temp = condition;

        temp[currentBlock["id"]] = "completed";
        setCondition(temp);
        patLogs(
            "ZIZO Module Submited (user clicked to end button)",
            inspectionId
        );
        //setDamageModuleCompleted(true);
        resumeFlowFinalSubmitHandler("zizo");
        setScreen("menu");
        setPage("inst");
    };

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const startCamera = () => {
        cameraStarted = true;
        webCamPromise = navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                window.stream = stream;
                track = stream.getTracks()[0];
                videoRef.current.srcObject = stream;
                setTimeout(() => {
                    setApiFlag(true);
                }, 500);
            });

        setPage("camera");
    };

    const captureImg = (imgURL) => {
        let region = Object.keys(config["video-page"]).includes("clientRegion") ? config["video-page"]["clientRegion"] : "eu";
        addImageToList({
            name: `${captured}.jpg`,
            imgData: imgURL,
            tag: `zizo_${count}`,
            region: region
        });
        captured++;
    };

    const back = () => {
        cameraStarted = false;
        patLogs("back button clicked", inspectionId);
        setScreen("menu");
        setPage("inst");
        logbugs = true;
    };

    const sendImageToLamda = async (image, tag) => {
        try {
            let result = await makeRequest("https://72mxy4glexfyf56zyu6mocbnne0xwmco.lambda-url.eu-central-1.on.aws/", {
                inspection_id: inspectionId,
                client_id: clientId,
                region: "eu-central-1",
                env: 0,
                image: image,
                tag: apiTag
            });
            if (result.statusText === "OK") {
                let lambdaObj = await result.json();
                if (lambdaObj.feedback.length === 0) {
                    if (tag === "Zoom_In") {
                        if (zoomInRes.length === 2) {
                            //to stop the further api call to be proc after we got the result
                            if (zoomInFlag) {
                                setApiFlag(false);
                                lambdaSendingFlag = false;
                                zoomInFlag = false;
                                setCamBoxFlag(false);
                                lambdaGuidRef.current.style.display = "none";
                                pathGuidRefLeft.current.style.display = "none";
                                pathGuidRefRight.current.style.display = "none";
                                gradiantRef.current.style.display = "flex";
                                zizoGuidance.current.style.display = "flex";
                                zizoArrow.current.style.display = "none";
                                zizoBtnYes.current.style.display = "flex";
                                zizoBtnNo.current.style.display = "flex";
                                zizoBtns.current.style.display = "flex";
                                zoomInRes = [];
                            }
                        }
                        else {
                            zoomInRes.push("true");
                        }
                    }
                    else if (tag === "Zoom_Out") {
                        if (zoomOutRes.length === 3) {
                            //to stop the further api call to be proc after we got the result
                            if (zoomOutFlag) {
                                setApiFlag(false);
                                zoomOutFlag = false;
                                setCamBoxFlag(false);
                                recordGifFlag = true;
                                setSelectGradText(2);
                                allowStopBtn = true;
                                if (recordGuidTxtRef.current.style) recordGuidTxtRef.current.style.display = "none";
                                pathGuidRefLeft.current.style.display = "none";
                                pathGuidRefRight.current.style.display = "none";
                                gradiantRef.current.style.display = "flex";
                                zizoGuidance.current.style.display = "flex";
                                zizoArrow.current.style.display = "none";
                                recordGifRef.current.style.paddingTop = "5%";
                                recordGifRef.current.style.width = "15%";
                            }
                        }
                        else {
                            zoomOutRes.push("true");
                        }
                    }
                }
                else {
                    if (tag === "Zoom_In") {
                        if (lambdaObj.feedback[0].includes("Please point your camera towards the car")) {
                            setSelectGuidanceText(1);
                        }
                        else {
                            if (zoomInFlag) {
                                pathGuidRefLeft.current.style.display = "block";
                                pathGuidRefRight.current.style.display = "block";
                                zizoArrow.current.style.display = "flex"
                                setSelectGuidanceText(2);
                            }
                        }
                    }
                }
            }
        }
        catch (err) {
            console.log("Zizo lambda calling error  ", err);
        }

    }

    const loadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (error) => reject(error);
            img.src = src;
        });
    };

    const reSizeImage = async (img) => {
        const img1 = await loadImage(img);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = 320;
        canvas.height = 320;
        context.drawImage(img1, 0, 0, 320, 320);
        let base64Data = canvas.toDataURL("image/jpeg");
        return base64Data;
    }

    const apiImageCalculation = async (tag) => {
        const cnv = canvasRef.current;
        const vw = videoRef.current;
        cnv.width = vw.videoWidth;
        cnv.height = vw.videoHeight;
        //console.log(vw.videoWidth, vw.videoHeight)
        cnv.getContext("2d").drawImage(vw, 0, 0);
        let imageBase64 = await reSizeImage(cnv.toDataURL("image/jpeg"));
        if (lambdaSendingFlag) {
            sendImageToLamda(imageBase64.replace(/^data:image\/jpeg;base64,/, ""), tag);
        }
        if (tag === "Zoom_Out") {
            captureImg(cnv.toDataURL("image/jpeg"));
        }
    };

    const takeMore = () => {
        setCount((prevCount) => ++prevCount);
        takeMoreList[takeMoreList.length - 1] = true;
        if (takeMoreList.length < listCount) {
            takeMoreList.push(false);
        }
        zoomInFlag = true
        setCamBoxFlag(true)
        zoomInRes = []
        zoomOutFlag = true,
            recordGifFlag = false
        setSelectGradText(0)
        zoomOutRes = []
        setSelectGuidanceText(0);
        apiCallTag = "Zoom_In";
        apiTag = "CAR_ZI";
        startCamera();
    }

    useEffect(() => {
        interval = setInterval(() => {
            setCurrentGif((prevImage) => (prevImage === 0 ? 1 : 0));
        }, 1200);

        return () => {
            clearInterval(interval);
        }
    }, []);

    useEffect(() => {
        if (apiFlag) {
            apiInterval = setInterval(() => {
                apiImageCalculation(apiCallTag);
            }, 3000);
        }
        else {
            clearInterval(apiInterval);
        }
    }, [apiFlag]);

    useEffect(() => {
        setLandscape(land());
    }, []);

    useEffect(() => {
        if (takeMoreList.length <= 3) alignment = true;
        else alignment = false;
    }, [alignmentFlag])

    return (
        <div className="zizo-root">
            {!isLandscape ? (
                <Rotate />
            ) : page === "inst" ? (
                <div className="zizo-inst">
                    <div className="current-gif" style={{height: "70%"}}>
                        {
                            currentGif === 0 ? <ZizoClose className="svgFar" /> : <ZizoFar className="svgFar"/>
                        }
                    </div>
                    <div id="zizo-btn">
                        <img
                            id="zizo-Btn-Gif"
                            src={getStartedGif} alt="123"
                            onClick={() => {
                                clearInterval(interval);
                                startCamera();
                            }}
                        />
                    </div>
                </div>
            ) : page === "zizo" ? (
                <div className="screen18 damage_size">
                    <h1 style={{ paddingTop: "3%" }}>{config["damage-page"]["select-screen-title"]}</h1>
                    <div className="post-sub">
                        <div
                            className="process-main"
                            style={{ paddingBottom: "3%" }}
                        >
                            <div
                                className="left-btn"
                                disabled={scrollX === 0}
                                onClick={() => slide(-200)}
                                style={alignment ? { display: "none" } : { display: "flex" }}
                            >
                                <img
                                    src={leftArrow}
                                    style={scrollX === 0 ? { opacity: "0.3" } : {}}
                                />
                            </div>
                            <div
                                className="process-steps"
                                ref={scrl}
                                onScroll={scrollCheck}
                                style={
                                    alignment
                                        ? { display: "flex", justifyContent: "center", width: "100vw" }
                                        : {
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            width: "80vw",
                                            overflowX: "scroll",
                                            scrollBehavior: "smooth",
                                        }
                                }
                            >
                                {takeMoreList.map((item, index) => {
                                    if (item) {
                                        return (
                                            <div key={index}>
                                                <div
                                                    className="menu-options"
                                                >
                                                    <div
                                                        className="process-vin"
                                                        style={{
                                                            border: `2px solid ${config["colors"]["complete-block"]}`,
                                                        }}
                                                    >
                                                        <div className="greenCircleTick">
                                                            <img src={white_tick} alt="1232"></img>
                                                        </div>
                                                        <img src={currentBlock["link"]} alt="123"></img>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={index}>
                                            <div
                                                className="menu-options"
                                            >
                                                <div
                                                    className="process-vin"
                                                    style={{
                                                        border: `2px solid gray`,
                                                    }}
                                                >
                                                    <img
                                                        src={item ? currentBlock["link"] : plusSvg}
                                                        alt="123"
                                                        style={{
                                                            width: "40%",
                                                            height: "50%",
                                                            opacity: .5
                                                        }}
                                                        onClick={!item && takeMore}>

                                                    </img>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div
                                className="right-btn"
                                disabled={scrolEnd}
                                onClick={() => slide(+200)}
                                style={alignment ? { display: "none" } : { display: "flex" }}
                            >
                                <img src={rightArrow} style={scrolEnd ? { opacity: "0.3" } : {}} />
                            </div>
                        </div>
                        <div className="InternetWarning">
                            <p>{showAlert && showAlert}</p>
                        </div>
                        <div className="damage-btn">
                            <div className="damage-btn-div">
                                <div
                                    className="btn process-btn"
                                    style={{
                                        backgroundColor: config["colors"]["btn"],
                                        color: "white"
                                    }}
                                    onClick={() => End()}
                                >
                                    {config["damage-page"]["end-btn"]}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : page === "camera" ? (
                <div id="vin-screen-zizo" style={{ position: "relative" }}>
                    <canvas ref={canvasRef} id="ios-canvas-zizo" style={
                        Object.keys(config).includes("aspectRatio")
                            ? { width: "100vw", height: "100vh", objectFit: "contain", backgroundColor: "black" }
                            : {}
                    }></canvas>
                    <video ref={videoRef} id="videoWindow-zizo" style={
                        Object.keys(config).includes("aspectRatio")
                            ? { width: "100vw", height: "100vh", objectFit: "contain", backgroundColor: "black" }
                            : {}} autoPlay playsInline>
                    </video>
                    <img ref={red_box} id="camera_red_box" src={camBoxFlag ? "https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/app-icon/camera_red_box.svg" : "https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/app-icon/camera_green_box.svg"} alt="123" />
                    <div id="zizo-camera-container">
                        <div id="zizo-camera-first-container">
                            <div id="zizo-arrow-guidance">
                                <div ref={zizoArrow} id="zizo-arrow">
                                    <img ref={pathGuidRefLeft} className="zizo_arrow_left-flex" src={arrrow} alt="123" />
                                    <img ref={pathGuidRefRight} className="zizo_arrow_right-flex" src={arrrow} alt="123" />
                                </div>
                                <div ref={zizoGuidance} id="zizo-guidance">
                                    <div
                                        ref={gradiantRef}
                                        id="zizo_gradiant-flex"
                                        style={{
                                            backgroundImage: `url(${gradiantSVG})`,
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                        }}
                                    >
                                        <p ref={gradiantTextRef} id="gradiant_text-flex">{gradiantText[selectGradText]}</p>
                                    </div>
                                </div>
                            </div>
                            <div id="zizo-recording-btn">
                                <div
                                    ref={recordingRef}
                                    id={recordBtnFlag ? "btn-android-start" : "btn-android-stop"}
                                    onClick={() => {
                                        if (recordBtnFlag) {
                                            // apiCallTag = "Zoom_Out";
                                            // apiTag = "CAR_ZO";
                                            // setApiFlag(true);
                                            lambdaSendingFlag = true;
                                            gradiantRef.current.style.display = "none";
                                            zizoGuidance.current.style.display = "none";
                                            zizoArrow.current.style.display = "flex";
                                            stopHand.current.style.display = "none";
                                            recordGifRef.current.style.display = "block";
                                            recordGuidTxtRef.current.style.display = "block";
                                            pathGuidRefLeft.current.style.display = "block";
                                            pathGuidRefLeft.current.style.transform = "rotate(160deg)";
                                            pathGuidRefRight.current.style.display = "block";
                                            pathGuidRefRight.current.style.transform = "rotate(25deg)";
                                            setRecordBtnFlag(false);
                                            setTimeout(() => {
                                                if (recordGuidTxtRef.current.style) recordGuidTxtRef.current.style.display = "none";
                                            }, 9000);
                                        }
                                        else {
                                            if (allowStopBtn) {
                                                setAlignmentFlag(!alignmentFlag);
                                                setRecordBtnFlag(true);
                                                setPage("zizo")
                                            }
                                        }
                                    }}
                                >
                                </div>
                            </div>
                        </div>
                        <div id="zizo-camera-secound-container">
                            <div ref={lambdaGuidRef} className="camera-box-inst-flex">
                                <p className="inst-inside-title-flex">{cameraGuidanceText[selectGuidanceText]}</p>
                            </div>
                            <div style={{ zIndex: 1 }} id="zizo-hand">
                                <img ref={stopHand} id="zezoStopHand-flex" src={zizoHand} alt="123" />
                            </div>
                            <div id="zizo-recording-container">
                                <img ref={recordGifRef} id="record_gif-flex" src={recordGifFlag ? greenRecord : redRecord} alt="123" />
                                <p ref={recordGuidTxtRef} id="recordGuidText-flex">Slowly move away from the car</p>
                            </div>
                            <div ref={zizoBtns} id="zizo-btns">
                                <div
                                    ref={zizoBtnNo}
                                    id="zizo_btn_no-flex"
                                    onClick={() => {
                                        setSelectGradText(3);
                                    }}
                                >
                                    <p className="zizo_btn_text-flex">No</p>
                                </div>
                                <div
                                    ref={zizoBtnYes}
                                    id="zizo_btn_yes-flex"
                                    onClick={() => {
                                        setCamBoxFlag(true);
                                        setSelectGradText(1);
                                        zizoBtnYes.current.style.display = "none";
                                        zizoBtnNo.current.style.display = "none";
                                        zizoBtns.current.style.display = "none";
                                        recordingRef.current.style.display = "flex";
                                        stopHand.current.style.display = "block";
                                        apiCallTag = "Zoom_Out";
                                        apiTag = "CAR_ZO";
                                        setApiFlag(true);
                                    }}
                                >
                                    <p className="zizo_btn_text-flex">Yes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null
            }
        </div >
    );
};

export default NewZIZO;

