import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context";
import NavbarP from "./NavBarP";
import Rotate from "../Rotate";
import Button from "../Button";
import white_tick from "../../img/white_tick.png";
import {
  userLogs,
  patLogs
} from "../../context/utils";

let allMandatoryCompleted = true;
let allCompleted = true;
let alignment = false;
let mandatoryMissing = [];
let totalMissing = [];
let logsbugs = true;
let interval;
let totalModuleComplete = "";
let track = null;

const WondleMultipleImage = () => {
  const {
    damageModuleCompleted,
    showAlert,
    config,
    setCurrentBlock,
    currentBlock,
    screen,
    setScreen,
    condition,
    subCondition,
    isLandscape,
    setLandscape,
    mandatory,
    subMandatory,
    completed,
    setCompleted,
    mandatoryCompleted,
    setMandatoryCompleted,
    count1,
    setCount1,
    setStopPing,
    inspectionId,
    showFeedBackWarning,
    tempStream,
    setTempStream,
    isSwitchToLandscape,
    setIsSwitchToLandscape,
    isIOS
  } = useContext(AppContext);

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const modalRef = useRef();
  const [reduce, setReduce] = useState(false);

  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
        width: Object.keys(config).includes("resolution")
          ? config["resolution"]["width"]
          : 1920,
        height: Object.keys(config).includes("resolution")
          ? config["resolution"]["height"]
          : 1080,
        facingMode: "environment",
        aspectRatio: 4 / 3,
        zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 },
      }
      : {
        width: Object.keys(config).includes("resolution")
          ? config["resolution"]["width"]
          : 1920,
        height: Object.keys(config).includes("resolution")
          ? config["resolution"]["height"]
          : 1080,
        facingMode: "environment",
        zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 },
      },
    audio: false,
  };

  window.onresize = () => {
    // patLogs(land() ? "Landscape Mode" : "Portrait Mode", inspectionId);
    //userLogs({ position: 11, screen_orientation: "yes", inspectionId });
    setLandscape(land() ? "Landscape Mode" : "Portrait Mode");
  };

  const success = () => {
    if (!allMandatoryCompleted || !allCompleted) {
      patLogs(
        `Submit button clicked and warning given to user that some module is not completed `, inspectionId);
      modalRef.current.style.display = "flex";
      return;
    }
    track.stop();
    clearInterval(interval);
    const elements = document.querySelectorAll('.pingsPort');
    elements.forEach(element => {
      element.style.top = "0px";
    });
    setScreen("port-success");
    // setScreen("block");
    // setCurrentBlock(config["blocks"][1]);
  };

  const modalSuccess = () => {
    patLogs(
      `Warning given to user, some module is not completed, still clicked to submit button`,
      inspectionId
    );
    clearInterval(interval);
    const elements = document.querySelectorAll('.pingsPort');
    elements.forEach(element => {
      element.style.top = "0px";
    });
    setScreen("port-success");
  };

  const removeModal = () => {
    // patLogs(
    //   `user clicked cross button to close warning and move to menu page`,
    //   inspectionId
    // );
    modalRef.current.style.display = "none";
  };


  useEffect(() => {
    if (logsbugs) {
      // patLogs(land() ? "Landscape Mode" : "Portrait Mode", inspectionId);
      userLogs({ position: 8, last_page: "portrait menu page", inspectionId });
      logsbugs = false;
    }
    setStopPing(true);
    allCompleted = true;
    allMandatoryCompleted = true;
    mandatoryMissing = [];
    totalMissing = [];
    setLandscape(land());
    if (currentBlock.length <= 3) {
      alignment = true;
    }
    Object.keys(subMandatory).map((item) => {
      Object.keys(subMandatory[item]).map((item2) => {
        if (subMandatory[item][item2] === "pending") {
          mandatoryMissing.push(item2);
          allMandatoryCompleted = false;
        }
      })
    });
    Object.keys(subCondition).map((item) => {
      Object.keys(subCondition[item]).map((item2) => {
        if (subCondition[item][item2] === "pending") {
          totalMissing.push(item2);
          allCompleted = false;
        }
      })
    });
    setCount1(count1 + 1);
    if (allCompleted) setCompleted(true);
    if (allMandatoryCompleted) setMandatoryCompleted(true);

    currentBlock["values"].map((e) => {
      if (e["name"].length > 14) {
        setReduce(true);
      }
    });

    totalModuleComplete = "";
    Object.keys(subCondition).map((e) => {
      if (subCondition[e] === "completed") {
        totalModuleComplete = e + "," + totalModuleComplete;
      }
    });
    userLogs({
      position: 13,
      completed_module: totalModuleComplete,
      inspectionId,
    });
    if (Object.keys(config).includes("TimeOut")) {
      setTimeout(() => {
        if (!["success"].includes(screen)) {
          setScreen("timeOut");
        }
      }, config["TimeOut"]["timeInMinutes"] * 1000 * 60);
    }
    if (isIOS && !isLandscape) {
      const elements = document.querySelectorAll('.pingsPort');
      elements.forEach(element => {
        element.style.top = "60px";
      });
    }
  }, [isLandscape]);

  useEffect(() => {
    if (!tempStream) {
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        window.stream = stream;
        track = stream.getTracks()[0];
        setTempStream(stream)
      });
    }

    console.log("outside ::: ", window.innerWidth)
  }, []);

  useEffect(() => { }, [showFeedBackWarning]);

  return isLandscape ? (
    <Rotate />
  ) : (
    <div className="process" style={{ position: "relative", justifyContent: "normal" }}>
      <NavbarP />
      <div
        className="process-main"
        style={{
          marginTop: "15dvh",
          height: "auto",
          width: "95%",
          flexWrap: "wrap",
          // columnGap: window.innerWidth > 419 ? "40px" : "",
          // rowGap: window.innerWidth > 419 ? "30px" : "",
          // justifyContent: window.innerWidth <= 389 ? "space-evenly" : window.innerWidth > 389 && window.innerWidth <= 419 ? "space-between" : "",
          columnGap: "5%",
          rowGap: "5%",
          justifyContent: "flex-start",
          padding: "0px 10px 0px 10px"
        }}>
        {currentBlock["values"].map((item, index) => {
          if (subCondition[currentBlock["id"]][item.name] === "completed") {
            return (
              <div
                className="menu-optionsP"
                style={reduce ? { fontSize: "13px", maxWidth: window.innerWidth > 400 ? "35%" : "30%" } : null}
                key={index}
              >
                {item["mandatory"] ? (
                  item["name"] === "Form Module Portrait" ? <h4 style={{ color: config["colors"]["complete-block"], margin: "0px" }} key={index}>{item["id"]}&#42;</h4>
                    :
                    <h4 style={{ color: config["colors"]["complete-block"], margin: "0px" }} key={index}>{item["name"]}&#42;</h4>
                ) : (
                  <h4
                    style={{ color: config["colors"]["complete-block"], margin: "0px" }}
                    key={index}
                  >
                    {item["name"]}
                  </h4>
                )}
                <div
                  className="process-vin"
                  style={{
                    border: `2px solid ${config["colors"]["complete-block"]}`,
                    width: "100%",
                    height: "100px",
                    margin: "0px"
                  }}
                >
                  <div className="greenCircleTick">
                    <img src={white_tick} alt="1232"></img>
                  </div>
                  <img src={item["link"]} alt="123"></img>
                </div>
              </div>
            );
          }
          return Object.keys(config).includes("directModule") && config["directModule"] ? "" : (
            <div
              className="menu-optionsP"
              style={reduce ? { fontSize: "14px", maxWidth: window.innerWidth > 400 ? "35%" : "30%" } : null}
              key={index}
            >
              {item["mandatory"] ? (
                item["name"] === "Form Module Portrait" ? <h4 style={{ margin: "0px" }} key={index}>{item["id"]}&#42;</h4> : <h4 style={{ margin: "0px" }} key={index}>{item["name"]}&#42;</h4>
              ) : (
                <h4 style={{ margin: "0px" }} key={index}>{item["name"]}</h4>
              )}
              <div
                className="process-vin"
                style={{
                  border: `1px solid ${config["colors"]["incomplete-block"]}`,
                  width: "100%",
                  height: "100px",
                  margin: "0px"
                }}
                onClick={() => {
                  if (["Front Capture", "Back Capture"].includes(item["tag"]) && Object.keys(config).includes("allowLandscapeBumper") && isIOS) {
                    const elements = document.querySelectorAll('.pingsPort');
                    elements.forEach(element => {
                      element.style.top = "10px";
                    });
                    setIsSwitchToLandscape(true);
                  }
                  setCurrentBlock(item);
                  patLogs(`click to ${item.tag}`, inspectionId);
                }}
              >
                <img src={item["link"]} alt="123"></img>
              </div>
            </div>
          );
        })}
      </div>
      <div id="menuP-mixed-container">
        <div className="InternetWarning"
          style={{ justifyContent: "normal" }}
        >
          <p>
            {showAlert && showAlert}
          </p>
        </div>
        <div className="modal-btn-container" style={{ width: "100%" }}>
          <Button
            style={{ width: "80%" }}
            text={config["menu-page"]["btn-text"]}
            disabled={!mandatoryCompleted}
            onclick={success}
          />
        </div>
      </div>

      <div ref={modalRef} className="menu-modal-container">
        {!mandatoryCompleted ? (
          <div className="menu-modal-port" style={{ height: "45%", width: "45%", padding: "2% 5%" }}>
            <h3>{config["menu-page"]["mandatory-modal-text"]}</h3>
            <h4 style={{ color: "red" }}>{mandatoryMissing.join(", ")}</h4>
            <div id="closeBtn" style={{ marginBottom: "20px" }}>
              <Button
                text="Close"
                onclick={removeModal}
                marginDefault="0px 25px 0px 25px"
              />
            </div>
          </div>
        ) : mandatoryCompleted && !completed ? (
          <div className="menu-modal-port">
            {!damageModuleCompleted &&
              Object.keys(config["menu-page"]).includes(
                "damageConfirmationPopup"
              ) &&
              damageModuleExists ? (
              <>
                <h3>{config["menu-page"]["damageConfirmationPopup"]}</h3>
                <br></br>
                <br></br>
                <div className="damageConfirmationPopup">
                  <Button
                    text={config["menu-page"]["damageConfirmationPopupYesBtn"]}
                    onclick={() => {
                      modalSuccess();
                      modalRef.current.style.display = "none";
                    }}
                  />
                  <Button
                    text={config["menu-page"]["damageConfirmationPopupNoBtn"]}
                    onclick={() => {
                      modalRef.current.style.display = "none";
                      removeModal();
                    }}
                  />
                </div>
              </>
            ) : (
              < div className="menu-modal-port">
                <h3>{config["menu-page"]["optional-modal-text"]}</h3>
                <h4 style={{ color: "red" }}>{totalMissing.join(", ")}</h4>
                <div className="modal-btn-container">
                  <Button
                    text="Close"
                    onclick={removeModal}
                    marginDefault="0px 25px 0px 25px"
                  />
                  <Button
                    text={config["menu-page"]["modal-btn-next"]}
                    onclick={() => {
                      modalRef.current.style.display = "none";
                      modalSuccess();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WondleMultipleImage;
